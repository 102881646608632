.pagination {
	list-style: none;
	padding: 0;
	text-align: center;

	li {
		display: inline-block;
		margin: 0 2px;

		&:first-child,
		&:last-child {
			display: none;
		}

		a {
			text-decoration: none;
			color: #424242;
			font-weight: 100;
			padding: 0.25em;
			opacity: 0.5;
			transition: 300ms all ease;
			font-size: 0.75em;

			&:hover {
				opacity: 1;
			}
		}

		&.active {
			a {
				opacity: 1;
			}
		}
	}

	@media screen and (min-width: $medium) {
		margin-top: 3em;
	}
}
