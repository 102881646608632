body {
	position: relative;
	overflow-x: hidden;
	width: 100%;
	margin: 0;

	font-family: $sans;

	&.menu-active,
	&.no-scroll {
		overflow: hidden;
	}
}

* {
	-webkit-font-smoothing: antialiased;
}
