.modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);

    transition: 300ms all ease;
    visibility: hidden;
    opacity: 0;
    max-height: 100vh;
    max-width: 100vw;
    display: flex;

    &.active {
        visibility: visible;
        opacity: 1;
    }

    &__inner {
        display: flex;
        align-items: center;
        margin: auto;
        height: 100%;

        transition: 300ms all ease;
        position: relative;
        text-align: center;

        video {
            max-width: 100%;
            max-height: 90vh;
            display: inline-block;
        }

        .about {
            max-height: 85vh;
            max-width: 85vw;
            background-color: #fff;
            padding: 1em;
            padding-top: 2em;
            overflow-y: scroll;

            @media screen and (min-width: $medium) {
                margin: auto;
                min-width: 500px;
            }

            @media screen and (min-width: $large) {
                max-width: 750px;
            }

            h1 {
                text-transform: uppercase;
                font-size: 0.8em;
                letter-spacing: 2px;
                margin-bottom: 3em;
            }

            p {
                padding: 0 30px;
                font-size: 1.2em;
                line-height: 2em;

                @media screen and (min-width: $medium) {
                    padding: 0 60px;
                    font-size: 1.44em;
                }
            }

            a {
                background: #000;
                border: 0;
                border-radius: 3em;
                color: #fff;
                display: inline-block;
                font-weight: 800;
                letter-spacing: 2px;
                margin: 3em 0 2em;
                padding: 1em 2em;
                text-transform: uppercase;
                text-decoration: none;
                transition: 300ms all ease;

                &:hover {
                    opacity: 0.3;
                }
            }
        }
    }

    &__close {
        position: absolute;
        top: 0.5em;
        right: 1em;
        color: #fff;
        text-decoration: none;
        font-size: 1.5em;
        padding: 0.25em;
        z-index: 1;

        @media screen and (max-width: 480px) {
            top: 0.3em;
            right: 0.6em;
        }

        @media screen and (max-width: 360px) {
            top: 0;
            right: 0.3em;
        }
    }

    &__video {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding-top: 100px;
            background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.8),
                transparent
            );
        }
    }

    &__videoControls {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2em;
        width: 92%;
        position: absolute;
        bottom: 0;

        img {
            width: 60px;
            margin-right: 1em;
            cursor: pointer;
            transition: 300ms all ease;
        }

        input[type="range"] {
            -webkit-appearance: none;
            background: rgba(255, 255, 255, 0.5);
            flex: 9;
            height: 1px;
            align-self: center;

            $thumbSize: 20px;
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
            }

            &:focus {
                outline: none;
            }

            &::-ms-track {
                width: 100%;
                cursor: pointer;

                background: transparent;
                border-color: transparent;
                color: transparent;
            }

            &::-webkit-slider-thumb {
                -webkit-appearance: none;

                height: $thumbSize;
                width: $thumbSize;
                border-radius: 100%;
                background: #ffffff;
                cursor: pointer;
                margin-bottom: -3px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
            }

            &::-moz-range-thumb {
                border: 1px solid #000000;
                height: $thumbSize;
                width: $thumbSize;
                border-radius: 100%;
                background: #ffffff;
                cursor: pointer;
            }

            &::-ms-thumb {
                border-radius: 100%;
                box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                border: 1px solid #000000;
                height: $thumbSize;
                width: $thumbSize;
                border-radius: 100%;
                background: #ffffff;
                cursor: pointer;
            }
        }
    }
}
