.poem {
	position: relative;
	height: 260px;
	width: 260px;
	margin: auto;
	margin: 4em auto 6em;
	display: block;
	text-decoration: none;
	text-align: left;

	&__back {
		background-color: #f4f4f4;
		width: 100%;
		height: 100%;
		margin: auto;
		padding: 15px;
		box-sizing: border-box;
		transform: translateX(-10px) translateY(-10px);
	}

	&__front {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		overflow: hidden;
		transform: translateX(10px) translateY(55px);
		box-sizing: border-box;
	}

	&__imageOverlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 18px;
		background: rgba(255, 0, 0, 0.6);
	}

	img {
		filter: grayscale(100%) contrast(130%);
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		top: -20%;
	}

	&__afterTitle {
		font-size: 10px;
		text-transform: uppercase;
		color: #414042;
		font-weight: 700;
		line-height: 14px;
		display: inline-block;
	}

	&__afterAuthor {
		display: block;
		font-size: 8px;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.5px;
		margin-top: 3px;
	}

	&__title {
		color: #fff;
		font-family: $serif;
		display: block;
		font-weight: 100;
		font-size: 2em;
		line-height: 32px;
		margin-bottom: 7px;
	}

	&__author {
		color: #fff;
		font-family: $serif;
		display: block;
		font-weight: 100;
		font-size: 1.2em;
	}

	&__category {
		position: absolute;
		text-transform: uppercase;
		bottom: 15px;
		left: 15px;
		font-weight: 600;
		letter-spacing: 1px;
		font-size: 10px;
		color: #fff;
	}

	@media screen and (min-width: $medium) {
		display: inline-block;
		margin: 4em 2em;

		vertical-align: top;

		&__front {
			transition: 300ms all ease;
		}

		&:hover {
			.poem__front {
				transform: translateX(10px) translateY(53px);
				box-shadow: 0px 3px 22px rgba(0, 0, 0, 0.3);
			}
		}
	}

	@media screen and (min-width: $large) {
		margin: 3em;
		margin-bottom: 5em;
	}
}

.poems {
	text-align: center;

	@media screen and (min-width: $medium) {
		width: 100%;
		max-width: 1120px;
		margin: auto;
	}
}
