.poemDetail {
	position: relative;
	text-align: center;

	@media screen and (min-width: $medium) {
		max-width: 500px;
		margin: auto;
	}
	img {
		max-width: 100%;
	}

	&__back {
		color: #fff;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 800;
		font-size: 0.9em;
		margin: 1em;
		margin-bottom: 2em;
		display: inline-block;
	}

	&__actions {
		text-align: center;
		margin-top: 2em;

		@media screen and (min-width: $medium) {
			position: absolute;
			width: 100px;
			left: 100%;
			top: 0;
			bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}
	}

	&__action {
		text-decoration: none;
		display: inline-block;
		margin: 0 1em;
		cursor: pointer;
		transition: 300ms all ease;

		img {
			max-width: 50px;
		}

		span {
			color: #fff;
			display: block;
			text-transform: uppercase;
			font-weight: 800;
			letter-spacing: 1px;
			margin-top: 0.5em;
			font-size: 0.8em;
		}

		&:hover {
			opacity: 0.5;
		}

		@media screen and (min-width: $medium) {
			margin: 1.5em;
		}
	}
}
