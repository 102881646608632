.header {
	@media screen and (min-width: $medium) {
		.body--poemDetail & {
			position: absolute;
			top: 20px;
			left: 20px;
			right: 20px;
		}
	}

	&__logoLink {
		img {
			width: 100px;
		}
	}

	&__actions {
		float: right;
		padding-top: 25px;
		position: relative;
		z-index: 1;

		a {
			display: inline-block;
			width: 35px;

			img {
				width: 100%;
			}
		}

		[data-toggle-menu] {
			opacity: 0.5;
			transition: 300ms all ease;

			.menu-active & {
				opacity: 1;
			}
		}
	}
}
