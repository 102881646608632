.slideoutMenu {
	$widthMoble: 300px;
	$widthDesktop: 600px;

	width: $widthMoble;
	height: 100vh;
	overflow-y: scroll;
	position: absolute;
	top: 0;
	right: -$widthMoble;
	background: #414042;
	padding: 15px;
	box-sizing: border-box;
	padding: 1.25em 3em;
	display: none;

	&.slideout-active {
		display: block;
	}

	@media screen and (min-width: $medium) {
		padding-right: 6em;
		padding-left: 5em;
		width: $widthDesktop;
		right: -$widthDesktop;
	}

	&__title {
		font-family: $serif;
		color: #fff;
		font-size: 2em;
		font-weight: 100;

		@media screen and (min-width: $medium) {
			font-size: 3em;
		}
	}

	ul {
		padding: 0;
		list-style: none;

		li {
			line-height: 1.15;
			margin-bottom: 1em;

			@media screen and (min-width: $medium) {
				margin-bottom: 2em;
			}

			a {
				text-decoration: none;
				font-family: $sans;
				font-size: 0.8em;
				color: #999999;
				transition: 300ms all ease;

				&:hover,
				&.active {
					color: #fff;
					border-color: #fff;
				}

				@media screen and (min-width: $medium) {
					font-size: 1.6em;
					line-height: 1.5;
				}
			}
		}
	}

	&__filters {
		display: block;
		list-style: none;

		li {
			display: inline-block;
			margin-bottom: 1em;
			margin-right: 6px;

			a {
				color: #999999;
				font-weight: 700;
				letter-spacing: 1px;

				@media screen and (min-width: $medium) {
					font-size: 0.8em !important;
				}

				&.active {
					border-bottom: 1px solid #999;
					padding-bottom: 5px;
				}
			}

			&.active {
				a {
					text-decoration: underline;
				}
			}
		}
	}
}
