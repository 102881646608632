.emailForm {
	max-height: 85vh;
	max-width: 70vw;
	background-color: #fff;
	padding: 1em;
	padding-top: 2em;

	@media screen and (min-width: $medium) {
		margin: auto;
		min-width: 500px;

		& > form {
			margin-bottom: 10vh;
		}
	}

	.form-error {
		font-weight: 800;
		text-align: left;
		display: block;
		color: #c0392b;
		margin-bottom: 1em;
	}

	.error {
		font-size: 0.7em;
		text-align: left;
		display: block;
		padding: 0.5em;
		color: #c0392b;
		border: 1px dashed #c0392b;
	}

	h1 {
		text-transform: uppercase;
		font-size: 0.8em;
		letter-spacing: 2px;
		margin-bottom: 3em;
	}

	input {
		display: inline-block;
		-webkit-appearance: none;
		border: 0;
		outline: none;
		width: 100%;
		border-bottom: 1px solid #ddd;
		transition: 300ms all ease;
		font-size: 0.8em;
		padding: 0.5em;
		background: none;
		box-sizing: border-box;
		margin-bottom: 2em;

		&:focus {
			border-color: #000;
		}
	}

	label {
		font-size: 0.8em;
		color: #7d7d7d;
		display: block;
		text-align: left;
		margin-left: 0.5em;
		letter-spacing: 0;
		margin-bottom: 0.5em;
	}

	textarea {
		border: 1px solid #ddd;
		width: 100%;
		max-width: 100%;
		min-height: 150px;
		outline: none;
		padding: 0.5em;
		box-sizing: border-box;
		transition: 300ms all ease;

		&:focus {
			border-color: #000;
		}
	}

	button {
		-webkit-appearance: none;
		border: 0;
		background: #000;
		border-radius: 50%;
		color: #fff;
		width: 100px;
		margin: auto;
		padding: 1em;
		text-transform: uppercase;
		font-weight: 800;
		letter-spacing: 2px;
		border-radius: 20px;
		transition: 300ms all ease;
		cursor: pointer;
		margin-top: 3em;

		&:hover {
			opacity: 0.3;
		}
	}
}
