.page {
	width: 100%;
	height: 100%;
	position: relative;

	transform: translateX(0);
	transition: 500ms ease all;
	backface-visibility: hidden;

	box-sizing: border-box;
	padding: 1.5em;
	padding-bottom: 3em;

	&::after {
		content: " ";
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		position: absolute;
		background-color: rgba(255, 255, 255, 0.9);
		visibility: hidden;
		opacity: 0;
		transition: 500ms all ease;
	}

	.menu-active & {
		transform: translate3d(-300px, 0, 0);

		@media screen and (min-width: $medium) {
			transform: translate3d(-600px, 0, 0);
		}

		&::after {
			visibility: visible;
			opacity: 1;
		}
	}

	&__titleContainer {
		margin-bottom: 1em;
		margin-top: 3em;
		text-align: center;

		@media screen and (min-width: $medium) {
			margin-top: -4em;
			margin-bottom: 6em;
		}
	}

	&__title {
		font-weight: 700;
		letter-spacing: 3px;
		font-size: 1.1em;
		color: #58595b;
		text-align: center;

		padding-bottom: 5px;
		border-bottom: 2px solid #f4f4f4;
		display: inline;
	}

	&__activeSearch {
		font-family: $sans;
		text-align: center;
		margin-bottom: 3em;

		h1,
		a {
			color: #7f7f7f;
			font-size: 1.2em;
			font-weight: 400;
		}

		a {
			font-size: 0.9em;
		}

		h1 {
			@media screen and (min-width: $medium) {
				max-width: 530px;
				line-height: 1.6;
				margin: auto;
				margin-bottom: 1em;
			}
		}
	}

	&__banner {
		background-color: #fff;
		text-align: center;
		font-weight: 600;
		line-height: 1.5;
		padding: 1em;
	}
}
